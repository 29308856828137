import React from 'react'
// import { createTheme } from '@mui/material/styles'
import AboutBanner from '../../assets/images/about-banner.svg'
import AboutBannerShape from '../../assets/images/about-banner-shape.svg';
import { Box, Grid, Container } from '@mui/material'
import '../about/about.css'

// const theme = createTheme();

const About = () => {
  return (
    <>
        <Box sx={{ 
            backgroundImage: `url(${AboutBannerShape})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
         }}>
            <Container maxWidth="xl">
                <Grid container alignItems="flex-end">
                    <Grid item lg={6} xs={6} md={6}>
                        <Box mt={3}>
                            <div className=''>
                                <h1 className='inner_banner_heading color-ED6F28'>About Us</h1>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item lg={6} xs={6} md={6}>
                        <Box sx={{ lineHeight: '0', marginTop: '54px', marginBottom: '20px', textAlign: 'center' }}>
                            <img className='about_img' src={AboutBanner} alt="sbout" />
                        </Box>
                    </Grid>
                    
                </Grid>
            </Container>
        </Box>
        <Box mt={3}>   
            <Container maxWidth="xl">
             <p className='banner-para'>BoringAnt VPN: Connecting Lives, Securely Welcome to BoringAnt VPN, where secure connections meet the power of community. In today's digitized world, staying connected with your loved ones and communities is more vital than ever. Unfortunately, digital barriers like geo-restrictions, data throttling, and insecure networks often stand in the way. That's where we come in.</p>
            </Container>
        </Box>
        <Box sx={{}}>
            <Container maxWidth="xl">
                <Box sx={{
                    py: {
                        md: 10,
                        xs: 4,
                    },
                    textAlign: 'center',
                }}>
                    <Box className='heading_devider' pb={4}>
                        <h2 className='secondary_heading'>Who We Are</h2>
                    </Box>
                    <Box className='para_devider' 
                    sx={{
                        py: {
                            md: 10,
                            xs: 4,
                        },
                    }}
                    >
                        <p className="secondary_para">
                            BoringAnt is a forward-thinking VPN provider that aims to do more than just safeguard your data; we're on a mission to bring people together. With a team made up of cybersecurity experts, seasoned entrepreneurs, and engineers, we've built an ecosystem that eliminates digital borders while ensuring your online safety.
                        </p>
                    </Box>
                </Box>
                <Box
                sx={{
                    py: {
                        md: 10,
                        xs: 4,
                    },
                    textAlign: 'center',
                }}
                >
                    <Box className='heading_devider' pb={4}>
                        <h2 className='secondary_heading'>What We Offer</h2>
                    </Box>
                    <Box className='para_devider' 
                    sx={{
                        py: {
                            md: 10,
                            xs: 4,
                        },
                    }}
                    >
                        <p className="secondary_para">
                        Unrestricted Access: Ever tried to watch your favorite show or join an online community, only to find that it's unavailable in your location? BoringAnt VPN removes these geo-barriers, granting you access to content and platforms from around the world.

Robust Security: As an executive of a computer security company would appreciate, your online safety is our priority. Our advanced encryption algorithms ensure that your data remains private and secure, whether you're working from a café or browsing at home.

High-Speed Connections: Slow internet is a buzzkill. Our optimized server infrastructure promises high-speed connectivity, making sure you never miss a beat in your online interactions.

Community Hubs: Unique to BoringAnt VPN are our Community Hubs—a feature allowing you to create or join online spaces to interact securely with like-minded individuals.

Tailored Plans: Whether you're an individual, a family, or a business, we offer customized plans to suit your unique needs.
                        </p>
                    </Box>
                </Box>
                {/* Why BoringAnt? */}
                <Box sx={{
                    py: {
                        md: 10,
                        xs: 4,
                    },
                    textAlign: 'center',
                }}>
                    <Box className='heading_devider' pb={4}>
                        <h2 className='secondary_heading'>Why BoringAnt?</h2>
                    </Box>
                    <Box className='para_devider' 
                    sx={{
                        py: {
                            md: 10,
                            xs: 4,
                        },
                    }}
                    >
                        <p className="secondary_para">
                        You might wonder why the name "BoringAnt." We believe in the strength and resilience of ant colonies, which work in perfect harmony to build something bigger than themselves. And let's be honest, the boring part? It's a nod to how seamless and straightforward our user experience is. You'll almost forget you're using a VPN—that's how "boringly" effective it is.
                        </p>
                    </Box>
                </Box>
                {/* Join Us in Our Mission */}
                <Box sx={{
                    py: {
                        md: 10,
                        xs: 4,
                    },
                    textAlign: 'center',
                }}>
                    <Box className='heading_devider' pb={4}>
                        <h2 className='secondary_heading'>Join Us in Our Mission</h2>
                    </Box>
                    <Box className='para_devider' 
                    sx={{
                        py: {
                            md: 10,
                            xs: 4,
                        },
                    }}
                    >
                        <p className="secondary_para">
                        We're not just a service; we're a community. By choosing BoringAnt, you're not only opting for a secure online experience but also joining a movement to bring the world closer, one connection at a time.Ready to be part of something bigger? Download BoringAnt VPN now and start connecting securely today!Stay ConnectedFor business inquiries, partnership opportunities, or to share your BoringAnt story, feel free to contact us.Thank you for trusting BoringAnt VPN to be your gateway to a safer, more inclusive digital world.
                        </p>
                    </Box>
                </Box>
            </Container>
        </Box>
        <Box py={5}></Box>
    </>
  )
}

export default About
