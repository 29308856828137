import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  useMediaQuery,
  useTheme,
  Hidden, // Import Hidden component
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/logo.svg';
import '../header/header.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../assets/images/loader.gif'

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShowLoader(true)
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [location.pathname]);
  useEffect(()=> { 
   setShowLoader(true)
  },[])

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
 

  return (
    <div>
    <CssBaseline />
    <AppBar position="static" sx={{ backgroundColor: '#000000'}}>
      <Toolbar>
        <Link to='/' style={{alignSelf: 'end', paddingTop: '10px'}}>
        <img className='site_log' src={Logo} alt="" />
        </Link>
        <Hidden lgDown>
          <List
            sx={{
              display: 'flex',
              gap: '16px',
              marginLeft: 'auto', // Align to the right
            }}
          >
            <ListItem button component={Link} to="/" className={`${location.pathname === '/' ? 'active-link' : ''} listItem`}>
              <ListItemText primary="Homepage" />
            </ListItem>
            <ListItem button component={Link} to="/about" className={`${location.pathname === '/about' ? 'active-link' : ''} listItem`}>
              <ListItemText primary="About us" />
            </ListItem>
            
            <ListItem button component={Link} to="/download" className={`${location.pathname === '/download' ? 'active-link' : ''} listItem`}>
              <ListItemText primary="Download" />
            </ListItem>
            <ListItem button component={Link} to="/contact" className={`${location.pathname === '/contact' ? 'active-link' : ''} listItem`} >
              <ListItemText primary="Contact us"  sx={{ whiteSpace: 'nowrap' }}/>
            </ListItem>
          </List>
        </Hidden>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{
              display: 'flex',
              marginLeft: 'auto',
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
    {isMobile && (
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer} PaperProps={{ sx: { width: 316 } }}>
        <div className="close_drawer"><CloseRoundedIcon onClick={closeDrawer}/></div>
        <List className='nav_lists'>
          <ListItem button component={Link} to="/" onClick={closeDrawer} className={location.pathname === '/' ? 'active-link' : ''}>
            <ListItemText className='nav_list' primary="Homepage" />
          </ListItem>
          <ListItem button component={Link} to="/about" onClick={closeDrawer} className={`${location.pathname === '/about' ? 'active-link' : ''} listItem-m`}>
            <ListItemText className='nav_list' primary="About us" />
          </ListItem>
          <ListItem button component={Link} to="/download" onClick={closeDrawer} className={`${location.pathname === '/download' ? 'active-link' : ''} listItem-m`}>
            <ListItemText className='nav_list' primary="Download" />
          </ListItem>
          <ListItem button component={Link} to="/contact" onClick={closeDrawer} className={`${location.pathname === '/contact' ? 'active-link' : ''} listItem-m`}>
            <ListItemText className='nav_list' primary="Contact us" />
          </ListItem>
        </List>
      </Drawer>
    )}
    {showLoader && (
        <div className="loader-wrap">
          <img className='loader' src={Loader} alt="footer logo" />
        </div>
      )}
  </div>
  );
}

export default Header;
