
import React from 'react';
import './App.css';
import Footer from './component/footer/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './component/header/header';
import Home from './pages/home/home';
import About from './pages/about/about';
import Download from './pages/download/download';
import Contact from './pages/contact/contact';
import PrivacyPolicy from './pages/privacy/privacyPolicy';
import HowToDeleteMyAccount from './pages/howtoDeleteMyAccount/howToDeleteMyAccount';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/download" element={<Download/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/how-to-delete-my-account" element={<HowToDeleteMyAccount/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
