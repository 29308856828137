import React, { useState, useRef } from 'react'
import ContactBg from '../../assets/images/contact-bg.svg';
import HeadingVector from '../../assets/images/heading-vector.svg';
import { Box, Container, Grid, TextField } from '@mui/material';
import '../contact/contact.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({

    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: "red" },
            },
        },
    },

})
const Contact = () => {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const numberRef = useRef(null);
    const countryRef = useRef(null);
    const companyNameRef = useRef(null);
    const messageRef = useRef(null);
    // Function to focus on the next input field

    const [isFormValid, setIsFormValid] = useState(false);

    const focusNextInput = (nextRef) => {
        if (nextRef && nextRef.current) {
            nextRef.current.focus();
        }
    };
    

    // Handle Enter key press
    const handleKeyPress = (event, nextRef) => {
        if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default form submission
        focusNextInput(nextRef); // Focus on the next input field
        }
        checkFormValidity();
    };

    const checkFormValidity = () => {
        // Check the required fields (name, email, and message)
        const isNameValid = nameRef.current.value !== '';
        const isEmailValid = emailRef.current.value !== '';
        const isMessageValid = messageRef.current.value !== '';

        // Check if all required fields are valid
        const isValid = isNameValid && isEmailValid && isMessageValid;

        // Check if any of the fields is empty, considering the case where you type and then delete text
        const isAnyFieldEmpty = nameRef.current.value === '' || emailRef.current.value === '' || messageRef.current.value === '';


        setIsFormValid(isValid && !isAnyFieldEmpty);
    };


  return (
    <div className='contact_page'>
        <Container maxWidth='xl'>
            <Box sx={{
                    pt: {
                        xs: 4,
                        md: 4,
                        lg: 10
                    },
                    textAlign: 'center',
                }} >
                <div className='banner-content'>
                    <h1 className='contact_heading'>Contact us</h1>
                    <img className='hvector_img' src={HeadingVector} alt="sbout" />
                </div>
            </Box>
        </Container>
        <Box  sx={{ 
            pt: {
                xs: 4,
                md: 17,
                lg: 20
            },
            backgroundImage: `url(${ContactBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '100%',
            height: '100%',
        }}>
            <Container maxWidth="xl">
                <Box pt={4} textAlign='center'>
                    <h3 className='contact_form_title'> Thank you for getting in touch!</h3>
                </Box>
            </Container>
                <Box py={10} px={4} sx={{background: '#ed6f28'}}>

                    <Container maxWidth="xl">
                    <ThemeProvider theme={theme}>
                    <form >
                    {/* spacing={{ xs: 4, md: 5, lg: 10 }} */}
                        <Grid container rowSpacing={8} columnSpacing={10}>
                            <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        fullWidth 
                                        id="standard-basic"
                                        label="Your Name"
                                        variant="standard"
                                        inputRef={nameRef}
                                        onKeyPress={(event) => handleKeyPress(event, emailRef)}
                                        onChange={checkFormValidity}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: {
                                                    xs: '12px',
                                                    md: '20px',
                                                    lg: '30px'
                                                },
                                                color: '#F2F2F24D',
                                                textTransform: 'capitalize',
                                                '&.Mui-focused' : {
                                                    color: '#ffffff',
                                                    '&:before' : {
                                                        borderBottom: '2px solid #ffffff',
                                                    }
                                                }
                                            },
                                        }}
                                        InputProps={{
                                            sx: {
                                                fontSize: {
                                                    xs: '12px',
                                                    md: '20px',
                                                    lg: '30px'
                                                },
                                                color: '#F2F2F2',
                                                paddingLeft: '0px',
                                                '&:after': {
                                                    border: '2px solid #fff',
                                                    borderRadius: 0, 
                                                },
                                                '&:before': {
                                                    borderBottom: '1px solid #fff',
                                                    borderRadius: 0,
                                                },
                                                '&:hover:not(.Mui-disabled):before': {
                                                    borderBottom: '2px solid #ffffff',
                                                  },
                                                '&:hover fieldset': {
                                                    border: '2px solid #fff',
                                                    borderRadius: 0,
                                                },
                                                '&:focus-within fieldset, &:focus-visible fieldset': {
                                                    border: '2px solid #fff',
                                                  },
                                                },
                                        }}
                                    />
                                    
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    fullWidth 
                                    required
                                    id="standard-basic" 
                                    label="Your Email"
                                    variant="standard"
                                    inputRef={emailRef}
                                    onKeyPress={(event) => handleKeyPress(event, numberRef)}
                                    onChange={checkFormValidity}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F24D',
                                            textTransform: 'capitalize',
                                            '&.Mui-focused' : {
                                                color: '#ffffff',
                                                '&:before' : {
                                                    borderBottom: '2px solid #ffffff',
                                                }
                                            }
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F2',
                                            paddingLeft: '0px',
                                            '&:after': {
                                                border: '2px solid #fff',
                                                borderRadius: 0, 
                                            },
                                            '&:before': {
                                                borderBottom: '1px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                borderBottom: '2px solid #ffffff',
                                              },
                                            '&:hover fieldset': {
                                                border: '2px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:focus-within fieldset, &:focus-visible fieldset': {
                                                border: '2px solid #fff',
                                              },
                                            },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    fullWidth 
                                    id="standard-basic" 
                                    label="Phone Number"
                                    variant="standard"
                                    inputRef={numberRef}
                                    onKeyPress={(event) => handleKeyPress(event, countryRef)}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F24D',
                                            textTransform: 'capitalize',
                                            '&.Mui-focused' : {
                                                color: '#ffffff',
                                                '&:before' : {
                                                    borderBottom: '2px solid #ffffff',
                                                }
                                            }
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F2',
                                            paddingLeft: '0px',
                                            '&:after': {
                                                border: '2px solid #fff',
                                                borderRadius: 0, 
                                            },
                                            '&:before': {
                                                borderBottom: '1px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                borderBottom: '2px solid #ffffff',
                                              },
                                            '&:hover fieldset': {
                                                border: '2px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:focus-within fieldset, &:focus-visible fieldset': {
                                                border: '2px solid #fff',
                                              },
                                            },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    fullWidth 
                                    id="standard-basic" 
                                    label="Country"
                                    variant="standard"
                                    inputRef={countryRef}
                                    onKeyPress={(event) => handleKeyPress(event, companyNameRef)}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F24D',
                                            textTransform: 'capitalize',
                                            '&.Mui-focused' : {
                                                color: '#ffffff',
                                                '&:before' : {
                                                    borderBottom: '2px solid #ffffff',
                                                }
                                            }
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F2',
                                            paddingLeft: '0px',
                                            '&:after': {
                                                border: '2px solid #fff',
                                                borderRadius: 0, 
                                            },
                                            '&:before': {
                                                borderBottom: '1px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                borderBottom: '2px solid #ffffff',
                                              },
                                            '&:hover fieldset': {
                                                border: '2px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:focus-within fieldset, &:focus-visible fieldset': {
                                                border: '2px solid #fff',
                                              },
                                            },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    fullWidth 
                                    id="standard-basic" 
                                    label="Company Name"
                                    variant="standard"
                                    inputRef={companyNameRef}
                                    onKeyPress={(event) => handleKeyPress(event, messageRef)}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F24D',
                                            textTransform: 'capitalize',
                                            '&.Mui-focused' : {
                                                color: '#ffffff',
                                                '&:before' : {
                                                    borderBottom: '2px solid #ffffff',
                                                }
                                            }
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F2',
                                            paddingLeft: '0px',
                                            '&:after': {
                                                border: '2px solid #fff',
                                                borderRadius: 0, 
                                            },
                                            '&:before': {
                                                borderBottom: '1px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                borderBottom: '2px solid #ffffff',
                                              },
                                            '&:hover fieldset': {
                                                border: '2px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:focus-within fieldset, &:focus-visible fieldset': {
                                                border: '2px solid #fff',
                                              },
                                            },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField 
                                    fullWidth 
                                    required
                                    id="standard-basic" 
                                    label="Message"
                                    variant="standard"
                                    inputRef={messageRef}
                                    onKeyPress={() => checkFormValidity()}
                                    onChange={checkFormValidity}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F24D',
                                            textTransform: 'capitalize',
                                            '&.Mui-focused' : {
                                                color: '#ffffff',
                                                '&:before' : {
                                                    borderBottom: '2px solid #ffffff',
                                                }
                                            }
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '12px',
                                                md: '20px',
                                                lg: '30px'
                                            },
                                            color: '#F2F2F2',
                                            paddingLeft: '0px',
                                            '&:after': {
                                                border: '2px solid #fff',
                                                borderRadius: 0, 
                                            },
                                            '&:before': {
                                                borderBottom: '1px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                borderBottom: '2px solid #ffffff',
                                              },
                                            '&:hover fieldset': {
                                                border: '2px solid #fff',
                                                borderRadius: 0,
                                            },
                                            '&:focus-within fieldset, &:focus-visible fieldset': {
                                                border: '2px solid #fff',
                                              },
                                            },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        
                        <Box sx={{
                            my: {
                                xs: 4,
                                sm: 8,
                                md: 15
                            }
                        }}
                        textAlign='center'>
                            <button
                                className={`submit-btn ${isFormValid ? 'enabled-btn' : 'disabled-btn'}`}
                                disabled={!isFormValid}
                            >
                                Submit
                            </button>
                        </Box>
                        </form>
                        </ThemeProvider>
                    </Container>
                </Box>
            
        </Box>
    </div>
  )
}

export default Contact
