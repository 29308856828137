import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
// import Loader from '../../assets/images/loader.gif'

// import { BrowserRouter } from 'react-router-dom';
 
// const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    {/* <Suspense fallback={<>Loading...</>}> */}
        <App />
      {/* </Suspense> */}
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

reportWebVitals();
