import React from 'react'
import PropTypes from 'prop-types';
// import AboutBanner from '../../assets/images/about-banner.svg'
import DownShape from '../../assets/images/down-shape.svg';
import DownloadBanner from '../../assets/images/download-banner.png';
// import SwipeableViews from 'react-swipeable-views';
import { Box, Grid, Container, Tabs, AppBar, Tab, Typography, List, ListItem } from '@mui/material'
import '../download/download.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  function Download() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
  

  return (
    <div className='download_page'>
        <Box className="download-sahpe">
            <Container maxWidth="xl">
                    <Grid container>
                        <Grid item lg={6} xs={12} md={6}>
                            <Box sx={{
                                pt: {
                                    xs: 3,
                                    sm: 5,
                                    md: 8,
                                    lg: 15,
                                }
                            }}>
                                <div className=''>
                                    <h1 className='secondary_banner_heading color-ED6F28'>Download<br/>the <span className='heading-middle-text'>BoringAnt</span> App</h1>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item lg={6} xs={12} md={6}>
                            <Box 
                            sx={{ 
                                mt: {
                                    sm: 4,
                                    md: 6,
                                    lg: 14,
                                },
                                mb: {
                                    sm: 2,
                                    md: 3,
                                    lg: 12,
                                },
                                lineHeight: '0',
                                textAlign: 'center' 
                            }}
                            >
                                <img className='download_img' src={DownloadBanner} alt="sbout" />
                            </Box>
                        </Grid>
                    </Grid>
            </Container>
            <img className='down_shape' src={DownShape} alt="banner shape" />
        </Box>
        <Box>
        
                <Box sx={{ bgcolor: '#0000', width: '100%' }}>
                    <AppBar position="static">
                        <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        sx={{
                            px: {
                                xs: 2,
                                md: 4,
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#ed6f28',
                                height: '4px', 
                                borderRadius: '10px',
                              },
                            background: '#212121',}}
                        >
                        <Tab className='tab_item' label="iOS" {...a11yProps(0)} />
                        <Tab className='tab_item' label="Android" {...a11yProps(1)} />
                        <Tab className='tab_item' label="Windows" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <div
                        axis="x"
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0}>
                            <Box py={10}>
                                <Container maxWidth="xl">
                                    <Grid container alignItems="center">
                                        <Grid item lg={7} xs={12}>
                                            <Box>
                                                <div className='download-content'>
                                                    <Box mb={4}>
                                                    <h2 className='tab-heading'>Download  BoringAnt VPN for iOS</h2>
                                                    </Box>
                                                    <List className='download_spec'>
                                                        <ListItem>Protect your privacy</ListItem>
                                                        <ListItem>Using public Wi-Fi without any concerns</ListItem>
                                                        <ListItem>Easy to use and enjoy your safe journey online.</ListItem>
                                                    </List>
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={5} xs={12}>
                                            <Box sx={{ 
                                                mt: {
                                                  xs: 4,
                                                  md: 6,  
                                                },
                                                textAlign: 'center' }}>
                                                    <Box mb={3}>
                                                        <a href="https://apps.apple.com/in/app/boringant/id6474852887" target='blank'>
                                                        <button className='secondary-btn'>Download App</button>
                                                        </a>
                                                    </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Box py={10}>
                                <Container maxWidth="xl">
                                    <Grid container alignItems="center">
                                        <Grid item lg={7} xs={12}>
                                            <Box>
                                                <div className='download-content'>
                                                    <Box mb={4}>
                                                    <h2 className='tab-heading'>Download BoringAnt VPN for Android</h2>
                                                    </Box>
                                                    <List className='download_spec'>
                                                        <ListItem>Protect your privacy</ListItem>
                                                        <ListItem>Using public Wi-Fi without any concerns</ListItem>
                                                        <ListItem>Easy to use and enjoy your safe journey online.</ListItem>
                                                    </List>
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={5} xs={12}>
                                            <Box
                                                sx={{ 
                                                mt: {
                                                  xs: 4,
                                                  md: 6,  
                                                },textAlign: 'center' }}>
                                                    <Box mb={3}>
                                                        <a href="https://play.google.com/store/apps/details?id=com.boringant.vpn">
                                                        <button className='secondary-btn'>Download App</button>
                                                        </a>
                                                    </Box>
                                                    <Box>
                                                        <a href="https://www.fileduffel.com/boringant/boringant-android-current.apk">
                                                        <button className='secondary-btn'>Download apk</button>
                                                        </a>
                                                    </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Box py={10}>
                                <Container maxWidth="xl">
                                    <Grid container alignItems="center">
                                        <Grid item lg={7} xs={12}>
                                            <Box>
                                                <div className='download-content'>
                                                    <Box mb={4}>
                                                    <h2 className='tab-heading'>Download BoringAnt VPN for Windows PC and laptop</h2>
                                                    </Box>
                                                    <List className='download_spec'>
                                                        <ListItem>Protect your privacy</ListItem>
                                                        <ListItem>Using public Wi-Fi without any concerns</ListItem>
                                                        <ListItem>Easy to use and enjoy your safe journey online.</ListItem>
                                                    </List>
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={5} xs={12}>
                                            <Box
                                                sx={{ 
                                                mt: {
                                                  xs: 4,
                                                  md: 6,  
                                                },textAlign: 'center' }}>
                                                    <a href="https://www.fileduffel.com/boringant/boringant-win-current.exe"><button className='secondary-btn'>Download App</button></a>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </TabPanel>
                    </div>
                </Box>
        </Box>
    </div>
  )
}

export default Download
