import React from 'react'
import FooterLogo from '../../assets/images/logo.svg'
import Instagram from '../../assets/images/instagram.svg'
import Facebook from '../../assets/images/facebook.svg'
import Twitter from '../../assets/images/twitter.svg'
import '../footer/footer.css'
import { Box, Container } from '@mui/material'

const Footer = () => {

  return (
    <>
      <div className='footer'>
        <Container maxWidth="xl">
        <div className='footer_inner'>
          <img className='footer_logo' src={FooterLogo} alt="footer logo" />
          <Box sx={{
            display: {
              xs: 'flex',
            },
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <p className='follow-text'>Follow us :</p>
            <Box className='social-icon' sx={{ display: 'flex', alignItems: 'center' }}>
              <a href="/"><img src={Instagram} alt="instagram" /></a>
              <a href="/"><img src={Facebook} alt="facebook" /></a>
              <a href="/"><img src={Twitter} alt="twitter" /></a>
            </Box>
          </Box>
        </div>
        </Container>
        <Box sx={{ borderBottom: '1px solid #5B5B5B99' }}></Box>
        <Container maxWidth="xl">
        <Box className='copyright'>
          <p>@ 2023 BoringAnt VPN All Rights Reserved</p>
          <div className='hdc-account'>
            <div className='policy'>
              <a href="/how-to-delete-my-account">How to delete my account</a>
            </div>
            <div className='policy'>
              <a href="/privacy-policy">Privacy policy</a>
            </div>
          </div>
        </Box>
        </Container>
      </div>
    </>
  )
}

export default Footer
