import React from 'react'
// import { createTheme } from '@mui/material/styles'
import HomeImg from '../../assets/images/banner-img.png'
import { Box, Grid, Container } from '@mui/material'
import '../home/home.css'
import FolderIcon from '../../assets/images/folder.svg'
import KeyIcon from '../../assets/images/key.svg'
import ProtectIcon from '../../assets/images/protect.svg'
import { Link } from 'react-router-dom'
import PlaceholderImage from "../../assets/images/placeholder.png";
import { LazyLoadImage } from 'react-lazy-load-image-component'

// const theme = createTheme();

const Home = () => {
    
  return (
    <>
    <div className='home_page'>
    <Box sx={{ backgroundColor: '#ED6F28' }}>
    <Container maxWidth="xl">
        <Grid container alignItems="center">
            <Grid item lg={7} xs={12} md={12}>
                <Box>
                    <div className='banner-content'>
                        <h1 className='banner_heading'>Safe and Stable</h1>
                        <Box mt={5}>
                            <Link to='/download'>
                                <button className='primary-btn'>
                                    Download
                                </button>
                            </Link>
                        </Box>
                    </div>
                </Box>
            </Grid>
            <Grid item lg={5} xs={12} md={12}>
                <Box sx={{ lineHeight: '0', marginTop: '54px', textAlign: 'center' }}>
                    {/* <img className='banner_img' src={HomeImg} alt="Banner" /> */}
                    <LazyLoadImage src={HomeImg}
                        className='banner_img'
                        PlaceholderSrc={PlaceholderImage}
                        alt="Banner"
                    />
                </Box>
            </Grid>
        </Grid>
        </Container>
    </Box>
    <Box 
        sx={{

            mx: {
                xs: 4,
                md: 10,
            },
            my: {
                xs: 4,
                md: 8
            },
            px: {
                xs: 4,
                md: 14,
            },
            py: {
                xs: 4,
                md: 5
            },
            backgroundColor: '#4D4D4D',
        }}
        >
        <Box textAlign="center">
            <Box mb={4}>
                <h2 className='secondary_heading'>What Is VPN ?</h2>
            </Box>
            <p className='banner_para'>A VPN serves as a crucial shield for your online privacy, helping to safeguard against unauthorized data breaches and cyberattacks. When you're on the go or working in public spaces, the temptation to connect to free WiFi is often irresistible. However, these networks come with inherent security risks. Cybercriminals can exploit these vulnerabilities to access sensitive information such as your passwords, browsing history, or even the messages you send. This is where the role of a VPN becomes invaluable. By concealing your IP address and encrypting your data, a VPN creates a secure online environment, offering you an added layer of protection.</p>  
        </Box>
    </Box>
    <Box className='inner-section'>
        <Box mb={10} textAlign="center">
            <h2 className='secondary_heading'>The Reasons of Using VPN</h2>
        </Box>
        <Container maxWidth="xl">
        <Grid container justifyContent="center">
            <Grid item lg={4} sm={12}>
                <Box className='reasons' 
                sx={{
                    mb: {
                        xs: 10,
                        md: 16,
                    }
                }}
                >
                    <div className='resons_circle'>
                        <div>
                            <img src={FolderIcon} alt="" />
                            <p className='reasons-para p-3'>Share files securely</p>
                        </div>
                    </div>
                </Box>
            </Grid>
            <Grid item lg={4} sm={12}>
                <Box className='reasons'
                    sx={{
                        mb: {
                            xs: 10,
                            md: 16,
                        }
                    }}
                >
                    <div className='resons_circle'>
                        <div>
                            <img src={KeyIcon} alt="" />
                            <p className='reasons-para p-3'>Keep your data safe</p>
                        </div>
                    </div>
                </Box>
            </Grid>
            <Grid item lg={4} sm={12}>
                <Box className='reasons'>
                    <div className='resons_circle'>
                        <div>
                            <img src={ProtectIcon} alt="" />
                            <p className='reasons-para p-3'>Protect your privacy</p>
                        </div>
                    </div>
                </Box>
            </Grid>
        </Grid>
        </Container>
    </Box>
    </div>
    </>
  ) 
}

export default Home
