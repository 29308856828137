import React from 'react'
import Privacyvector from '../../assets/images/htd.svg';
import { Box, Grid, Container } from '@mui/material'
import '../howtoDeleteMyAccount/howToDeleteMyAccount.css'

const HowToDeleteMyAccount = () => {
  return (
    <>
        <div className='how_to_delete_page'>
            <img className='policy-vector' src={Privacyvector} alt="policy" />
        <Box sx={{ 
         }}>
            <Container maxWidth="xl">
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{
                                pt: {
                                    xs: 4,
                                    md: 6,
                                    lg: 10,
                                }
                            }}>
                                <div className=''>
                                    <h1 className='contact_heading'>How To Delete My Account</h1>
                                </div>
                            </Box>
                        </Grid>
                        
                    <Grid className='policy-content' item lg={12}>
                        <Box sx={{
                                pt: {
                                    xs: 15,
                                    sm: 15,
                                    md: 22,
                                    lg: 25,
                                }
                            }}
                            >
                        <p className='policy-content'>
                            How to Request Account Deletion for BoringAnt?
                        </p>
                        <p>
                            If you wish to delete your BoringAnt account and all associated information, please follow the instructions below:
                        </p>
                        <Box pb={5}>
                        <p>1. **Initiate the Request:** </p>
                        <ul className='policy-content'>
                            <li>- Compose an email to delete@boringant.com. </li>
                            <li>- In the body of your email, please provide your username or the email address associated with your BoringAnt account.</li>
                        </ul>
                        </Box>
                        <Box pb={5}>
                        <p>2. **Verify Your Identity:** </p>
                        <ul className='policy-content'>
                            <li>- After we receive your email, we will send a verification email to your registered email address. </li>
                            <li>- This is a crucial step to ensure the security of your account and confirm that the deletion request is genuine.</li>
                        </ul>
                        </Box>
                        <Box pb={5}>
                        <p>3. **Confirm Deletion:** </p>
                        <p>
                        - Open the verification email sent by BoringAnt. 
                        </p>
                        </Box>
                        <Box pb={5}>
                        <p>
                        Follow the provided instructions within the email to proceed with the deletion of your account.Please note that once your account is deleted, all data associated with it will be permanently removed from our systems and cannot be recovered. If you have any questions or encounter any issues during the process, please do not hesitate to contact our support team for assistance.
                        </p>
                        </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box>
        </Box>
    </div>
    </>
  )
}

export default HowToDeleteMyAccount
